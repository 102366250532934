<template>
  <div>
    <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
    <div class="top-widget">
      <!-- <div
        class="alert-floating"
        v-show="alertSuccess && user.process.status != 'error'"
      >
        <p>Updated</p>
      </div> -->

      <div class="ec-modal ec-overlay" v-show="deleteAccountModal">
        <div class="ec-dialog">
          <div class="ec-body">
            <div class="row">
              <div class="col-md-8">
                <h4>Delete account</h4>
                <p>
                  By confirming the deletion of your profile you also confirm
                  the deletion of all the data we hold on you will either be
                  deletd or anonymized permanently.
                </p>
                <p>
                  Including all ongoing thesis collaborations, inviations,offers
                  and groups you maybe be involved in.
                </p>
                <p><b>Please allow 48hours for us to delete the account</b></p>
              </div>
              <div class="col-md-4">
                <div class="close-modal">
                  <i class="fa fa-times" v-on:click="deleteAccountModal = false"></i>
                </div>
                <div class="user-form-buttons text-right">
                  <button class="btn btn-primary ml-2" @click="deletionConfirmed">
                    Confirm deletion
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MasterHeader title="User Profile" />
    <div>
      <div v-if="user" class="container">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="user-flow" v-if="user">
              <div class="user-splash"></div>
              <div class="user-col">
                <div class="user-enm-header">
                  <h4>{{ user.firstName }} {{ user.lastName }}</h4>
                </div>
                <div class="user-enm-body">
                  <ul>
                    <li>
                      <a href="#" @click="chngMenu('account')" data-value="account"
                        v-bind:class="{ active: menu === 'account' }">Account</a>
                    </li>
                    <li>
                      <a href="#" @click="chngMenu('password')" data-value="password"
                        v-bind:class="{ active: menu === 'password' }">Password</a>
                    </li>

                    <!-- <li v-if="user.role === 'Professional'"><a href="#" @click="chngMenu('interests')" data-value="interests" v-bind:class="{'active':(menu === 'interests')}">Interests</a></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <div class="user-flow" v-if="user">
              <div class="user-splash"></div>
              <div class="user-form p-0">
                <div class="user-enm-header">
                  <h4>{{ heading }}</h4>
                  <a href="#delete" class="delete-ac-btn" v-on:click="deleteAccountModal = true">Delete account</a>
                </div>
                <div class="user-enm-form" :class="menu">
                  <!-- <div
                    class="alert alert-danger"
                    v-show="user.process.status === 'error'"
                  >
                    {{ user.process.message }}
                  </div> -->
                  <form @submit.prevent="updateUserDetails" v-if="menu === 'account'">
                    <div class="form-group">
                      <label for="">First name *</label>
                      <input type="text" class="form-control form-control-lg" v-model="user.firstName"
                        placeholder="Enter your first name" autofocus required />
                    </div>
                    <div class="form-group">
                      <label for="">Last name</label>
                      <input type="text" class="form-control form-control-lg" v-model="user.lastName"
                        placeholder="Enter your last name" />
                    </div>

                    <div class="form-group">
                      <label for="">Email</label>
                      <input type="text" disabled class="form-control form-control-lg" v-model="user.email"
                        placeholder="Please enter your email" />
                    </div>

                    <div v-if="user.role === 'Professional'">
                      <!-- field of interest -->
                      <!-- <div class="form-group">
                      <label for="">Field of interest</label>
                      
                        <multiselect
                          v-model="user.interests"
                          disabled="disabled"
                          :options="jobsData"
                          :showLabels="false"
                          group-values="subjobs"
                          group-label="parent"
                          placeholder="Type to search"
                          track-by="name"
                          label="name"
                          ><span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          ></multiselect
                        >
                     
                    </div> -->
                      <div class="form-group">
                        <label for="">Current role </label>
                        <select v-model="user.designation" class="form-control" style="color:#000">
                          <option v-for="(role, index) in roles" :key="index" :value="role">
                            {{ role }}
                          </option>
                        </select>
                      </div>




                      <div class="form-group">
                        <label for="">Account type</label>
                        <div class="custom-control custom-radio pb-3">
                          <input type="radio" checked class="custom-control-input" name="role" id="roleProfessional">
                          <label class="custom-control-label" for="roleProfessional">Professional</label>

                        </div>

                      </div>
                    </div>

                    <div class="user-form-buttons">
                      <!-- <span class="user-steps" v-if="user.role === 'Professional'">Step 1 out of {{ maxStep-1 }}</span>
                    <span class="user-steps" v-if="user.role === 'Student'">Step 1 out of {{ maxStep }}</span> -->
                      <button class="btn btn-primary ml-2">Save</button>
                    </div>
                  </form>

                  <form @submit.prevent="updateUserPassword" v-if="menu === 'password'">
                    <div class="form-group">
                      <label for="">Current password</label>
                      <input type="password" class="form-control form-control-lg" v-on:keyup="reenterCurrentPassword"
                        v-model="oldPassword" placeholder="Enter your current password" required />
                      <p class="alert alert-danger mt-2" v-show="currentPasswordError">
                        <i class="fa fa-exclamation-triangle"></i> Wrong current
                        password
                      </p>
                    </div>
                    <div class="form-group">
                      <label for="">New password </label>
                      <input type="password" class="form-control form-control-lg" v-on:keyup="passwordEntry"
                        v-model="newPassword" placeholder="Enter your new password" required />
                    </div>
                    <div class="form-group">
                      <label for="">Confirm new password </label>
                      <input type="password" class="form-control form-control-lg" v-on:keyup="passwordEntry"
                        v-model="cnfPassword" placeholder="Confirm your new password" required />
                      <p class="alert alert-danger mt-2" v-show="cnfPasswordMissmatch">
                        <i class="fa fa-exclamation-triangle"></i> New password
                        and confirm is not matched
                      </p>
                      <p class="alert alert-danger mt-2" v-show="passwordChangeErrorMessage">
                        {{ passwordChangeErrorMessage }}</p>
                      <p class="alert alert-success mt-2" v-show="userMessage">{{ userMessage }}</p>
                    </div>

                    <!-- <div class="form-group">
                    <label for="">First name*</label>updateUserPassword
                    <input type="text" class="form-control form-control-lg" v-model="user.firstName" required>
                </div>
                <div class="form-group">
                    <label for="">Last name*</label>
                    <input type="text" class="form-control form-control-lg" v-model="user.lastName" required>
                </div>
                <div class="text-muted">
                    *Required
                </div> -->
                    <div class="user-form-buttons">
                      <!-- <span class="user-steps" v-if="user.role === 'Professional'">Step 2 out of {{ maxStep-1 }}</span> -->
                      <!-- <span class="user-steps" v-if="user.role === 'Student'">Step 2 out of {{ maxStep }}</span> -->
                      <!-- <span class="btn btn-secondary" type="button" @click="previousStep()">Previous</span> -->
                      <!-- <button class="btn btn-primary ml-2" v-if="user.role === 'Student'">Next</button> -->
                      <button class="btn btn-primary ml-2">Save</button>
                    </div>
                  </form>

                  <!-- interests section -->
                  <!-- <form @submit.prevent="updateUserDetails" v-if="menu === 'interests'">
         
                <div class="form-group">
                    <label for="">Your area of interest *</label>
                    <select v-model="user.interests" class="form-control form-control-lg" required>
                    <option v-for="(interest, index) in interests" :key="index" :value="{id: interest.id, name: interest.dk_name}">
                        {{ interest.dk_name }}
                    </option>
                    </select> 
                </div>                   
                <div class="user-form-buttons">
  
                    <button class="btn btn-primary ml-2">Save</button>
                </div>
                </form>     -->

                  <form @submit.prevent="updateUserDetails" v-if="menu === 'university' && user.role === 'Student'
                    ">
                    <!-- <div class="form-group">
                <label>Country *</label>
                <select v-model="user.country" class="form-control form-control-lg" @change="reset('country')" required>
                    <option v-for="(country, index) in filterByCountries" :key="index">{{country.country}}</option>
                </select> 
                </div> -->

                    <div class="user-form-wizard" :class="user.area ? '' : 'guide'">
                      <div class="form-group">
                        <label>Graduation semester *</label>
                        <select v-model="user.graduatingSemester" class="form-control form-control-lg" required>
                          <option v-for="(semester, index) in semesters" :key="index">{{ semester.value }}</option>
                        </select>
                      </div>

                      <div class="form-group" v-if="user.country &&
                        typeof filterInstitutionsByCountry !==
                        typeof undefined
                        " @change="reset('institution')">
                        <label>Institution *</label>
                        <select class="form-control form-control-lg" v-model="user.institution" required>
                          <option value="" v-if="filterInstitutionsByCountry.length !== 1">Choose one</option>
                          <option v-for="(institute,
                            index) in filterInstitutionsByCountry" :key="index"
                            :value="{ id: institute.id, name: institute.name }">
                            {{ institute.name }}
                          </option>
                        </select>
                      </div>

                      <img :src="'/assets/institutions/' +
                        user.institution.id +
                        '.png'
                        " class="img-fluid ec-img" :title="user.institution.name" />

                      <div class="form-group" v-if="user.institution &&
                        typeof filterFacultyByInstitution !==
                        typeof undefined
                        ">
                        <label>Choose faculty *</label>
                        <select class="form-control form-control-lg" v-model="user.faculty" @change="reset('faculty')"
                          required>
                          <option value="" v-if="filterFacultyByInstitution.length !== 1">Choose one</option>
                          <option v-for="(faculty,
                            index) in filterFacultyByInstitution" :key="index" :value="faculty.name">
                            {{ faculty.name }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="user.faculty &&
                        typeof filterDegreeByFaculty !== typeof undefined
                        ">
                        <label>Degree *</label>
                        <select class="form-control form-control-lg" v-model="user.degree" @change="reset('degree')"
                          required>
                          <option value="" v-if="filterDegreeByFaculty.length !== 1">Choose one</option>
                          <option v-for="(degree, index) in filterDegreeByFaculty" :key="index" :value="degree.name">
                            {{ degree.name }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group" v-if="user.degree &&
                        typeof filterAreaByDegree !== typeof undefined
                        ">
                        <label>Area *</label>
                        <select class="form-control form-control-lg" v-model="user.area" required>
                          <option value="" v-if="filterAreaByDegree.length !== 1">Choose one</option>
                          <option v-for="(area, index) in filterAreaByDegree" :key="index" :value="area.degreeName">
                            {{ area.degreeName }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <p></p>
                    <br />
                    <p></p>

                    <div class="user-form-buttons">
                      <button class="btn btn-primary ml-2">Save</button>
                    </div>
                  </form>

                  <form @submit.prevent="updateUserDetails" v-if="menu === 'description'">
                    <div class="form-group">
                      <label for="">Description</label>
                      <plainTextEditor v-model="user.description"></plainTextEditor>
                    </div>

                    <div class="form-group" style="margin-bottom:4rem">
                      <label for="">Passion</label>
                      <plainTextEditor v-model="user.passion"></plainTextEditor>
                    </div>

                    <div class="user-form-buttons">
                      <button class="btn btn-primary ml-2" style="">
                        Save
                      </button>
                    </div>
                  </form>

                  <form v-if="menu === 'hardSkills'">
                    <hardSkills v-model="user.hardSkills" @updateHardSkills="updateUserDetails"></hardSkills>
                  </form>

                  <form v-if="menu === 'coreSkills'">
                    <coreSkills v-model="user.coreSkills" @updateCoreSkills="updateUserDetails"></coreSkills>
                  </form>

                  <form @submit.prevent="updateUserDetails" v-if="menu === 'jobs'">
                    <div class="profoile-jobs">
                      <div class="pj-selected" v-if="user.jobs.length != 0">
                        <span>Position Selected: </span>
                        <ul>
                          <li v-for="(sj, i) in user.jobs" :key="i">
                            <span v-if="sj.name.indexOf('All') != -1 &&
                              sj.name.length == 3
                              "><i class="fas fa-times" @click="removeSelectedJob(sj)"></i>
                              {{ sj.catgeory_name }} ({{ sj.name }})</span>
                            <span v-else><i class="fas fa-times" @click="removeSelectedJob(sj)"></i>
                              {{ sj.name }}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="profoile-jobs">
                        <div class="pj-loading" v-show="!jobsData">
                          <i class="fas fa-circle-notch fa-spin"></i>
                        </div>
                        <div v-if="jobsData">
                          <ul class="job-ctg" style="margin-bottom:4rem">
                            <li v-for="(jobs, index) in jobsData" :key="index">
                              <a @click="toggleJobs">{{ jobs.name }}
                                <span>{{ jobs.description }}</span>
                              </a>
                              <JobLists v-if="jobs.subjobs" :selectedJobs="user.jobs" :jobsData="jobs.subjobs"
                                :category="jobs.id" @jobSelection="handleJobChange"></JobLists>
                              <!-- <ul class="job-lists" v-if="jobs.subjobs">
                                                <li v-for="(job,ind) in jobs.subjobs" :key="ind">
                                                    <label class="control control-checkbox">
                                                        {{job.name}}
                                                            <input type="checkbox" v-model="user.jobs" :value="job" @change="selectJobs($event,job)"/>
                                                        <div class="control_indicator"></div>
                                                    </label>
                                                </li>
                                            </ul> -->
                            </li>
                          </ul>
                          <div class="user-form-buttons">
                            <button class="btn btn-primary ml-2" style="">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import MasterHeader from "@/components/MasterHeader.vue";
import SignUpModal from '../../components/signUpModal.vue';
import { base64ToString } from "../../utils/utils";

const userRole = base64ToString(localStorage.getItem('i_am'))

export default {
  data: function () {
    return {
      menu: "account",
      heading: "Account",
      step: 1,
      alertSuccess: false,
      cnfPasswordMissmatch: false,
      formSubmitable: true,
      deleteAccountModal: false,
      accountDeleted: false,
      oldPassword: "",
      newPassword: "",
      cnfPassword: "",
      maxStep: 3,
      characters: 0,
      isCharactersLimitExceeded: false,
      passionCharacter: 0,
      isPassionCharactersLimitExceeded: false,
      jobsData: false,
      roles: [
        "CEO",
        "CHRO",
        "CPO",
        "Other C-Level",
        "HR Director",
        "HR Manager",
        "HR Specialist",
        "HR Assistant",
        "Recruiter",
        "Manager",
        "Team-Lead",
        "Other",
      ],
    };
  },
  components: {
    MasterHeader,
  },
  created() {
    const userType = base64ToString(localStorage.getItem('i_am'))
    const userVerified = localStorage.getItem('u-verified')
    if (userVerified == 0 && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
  },
  updated() {
    if (this.userMessage) {
      setTimeout(() => {
        this.$store.dispatch('resetUserMessage')
      }, 3000);
    }
  },
  computed: {
    passwordChangeErrorMessage() {
      return this.$store.getters.userErrorMessage;
    },
    userMessage() {
      return this.$store.getters.userMessage
    },
    user() {
      if (userRole === 'student') {
        return this.$store.getters.studentData
      }
      if (userRole === 'professional') return this.$store.getters.professionalData
      return []
    },
    currentPasswordError: function () {
      var vm = this;
      return JSON.parse(JSON.stringify(vm.$store.state.user))
        .isCurrentPasswordError;
    },
    isPasswordUpdated: function () {
      var vm = this;
      return JSON.parse(JSON.stringify(vm.$store.state.user)).isPasswordUpdated;
    },
  },
  methods: {
    passwordEntry: function (target) {
      //console.log(this.user.newPassword,this.user.confirmNewPassword);
      if (
        this.newPassword != this.cnfPassword &&
        this.cnfPassword != "" &&
        typeof this.cnfPassword != "undefined"
      ) {
        this.formSubmitable = false;
        this.cnfPasswordMissmatch = true;
      } else {
        this.cnfPasswordMissmatch = false;
        this.formSubmitable = true;
      }
    },
    showAlert: function () {
      this.alertSuccess = true;
      setTimeout(
        function () {
          this.alertSuccess = false;
        }.bind(this),
        6000
      );
    },
    chngMenu: function (target) {
      var vm = this;
      this.menu = target;

      switch (target) {
        case "account":
          this.heading = "Account";
          break;
        case "password":
          this.heading = "Password";
          break;
        case "university":
          this.heading = "University Info";
          break;
        case "description":
          this.heading = "Description";
          break;
        case "hardSkills":
          this.heading = "Professional Skills";
          break;
        case "coreSkills":
          this.heading = "Core Skills";
          break;
        case "jobs":
          this.heading = "Desired Jobs";
          this.loadJobs();
          break;
        case "interests":
          this.heading = "Interests";
          break;
        default:
          break;
      }
    },
    reenterCurrentPassword: function (target) {
      // this.$store.commit("setCurrentPasswordError", false);
      //this.oldPassword = target.code;
      //this.currentPasswordError = false;
    },
    updateUserPassword: function () {
      // if (!this.currentPasswordError) {
      this.$store.dispatch("updatePassword", {
        userEmail: this.user.email,
        userOldPassword: this.oldPassword,
        userNewPassword: this.newPassword,
      });
      if (String(this.isPasswordUpdated)) {
        this.showAlert();
      }
      // }
    },
    deletionConfirmed: function () {
      // todo do we need to send an email to the team?
      var vm = this;
      const templateData = {
        toEmail: "info@excelerate.careers",
        template: "Account deletion request",
        firstName: vm.user.firstName,
        lastName: vm.user.lastName,
        param1: vm.user.userId,
        param2: new Date().toLocaleString(),
      };
      this.accountDeleted = true;
      this.$store.dispatch("sendMail", templateData);
      this.$store.dispatch("requestAccountDeletion", 1);
      this.$store.dispatch("accountDeleted", true);
      this.$store.dispatch("signOutAction");
    },
    updateUserDetails: function () {
      var vm = this;
      //for students

      if (this.user.role === "Student") {
        this.user.designation = "";
        this.user.interests = "";
        this.user.subscription = "",
          this.user.subscriptionPlan = "",
          this.$store.dispatch("updateUserDetails", this.user);
        this.showAlert();
      } else if (this.user.role === "Student") {
        this.$store.dispatch("updateUserDetails", this.user);
        this.showAlert();
      }
      //for professionals
      if (this.user.role === "Professional") {

        this.$store.dispatch("updateUserDetails", this.user);
        this.showAlert();
      }
    },
    //Jobs
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option--highlight {
  background: #bd1a77;
  outline: none;
  color: #fff;
}

.multiselect {
  color: #5A2871;
  font-size: 14px;
  background: #fff;
}

.multiselect,
.multiselect__single {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.multiselect__single {
  margin-bottom: 0px;
  color: #000;
}

.multiselect__tags {
  font-family: "Poppins", sans-serif;
  color: #5A2871;
  font-size: 14px;
  border: 1px solid #dfd6df !important;
  border-radius: 4px;
  padding: 6px 20px 0 8px;
  min-height: 35px !important;
}

.multiselect__placeholder {
  margin-bottom: 4px;
}
</style>
<style scoped>
.user-flow {
  max-width: unset !important;
  border: unset !important;
  border-radius: 4px;
  background: #ffffff;
  -webkit-box-shadow: 0 4px 9px #dedddd;
  box-shadow: 0 4px 9px #dedddd;
  margin: 20px auto 0 auto;
  overflow: hidden;
}

.user-flow .user-col {
  position: relative;
  padding: 0 !important;
}

.user-enm-header {
  padding: 1rem 1rem 0.9rem;
}

.user-enm-header h4 {
  font-size: 16px;
  color: black;
  background: #fff;
  display: inline;
}

a.delete-ac-btn {
  float: right;
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-decoration: underline;
}

.user-enm-body {
  min-height: 220px;
  min-height: 220px;
  background: linear-gradient(#f9f9f9 5%, #ffffff 40%);
}

.user-enm-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-enm-body ul li a {
  display: block;
  padding: 0.6rem 1rem;
  font-size: 15px;
  font-weight: 500;
  color: #5A2871d1;
  text-decoration: none;
}

.user-enm-body ul li:hover a {
  color: #5A2871d1;
}

.user-enm-body ul li a.active {
  text-decoration: underline;
}

#app #app-master #page .user-flow .user-form form {
  padding: 0;
}

.user-enm-form {
  padding: 2.2rem 2rem;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(5%, #f9f9f9),
      color-stop(40%, #ffffff));
  background: linear-gradient(#f9f9f9 5%, #ffffff 40%);
}

.user-enm-form.university {
  padding: 1.8rem 1.4rem;
}

.user-enm-form label {
  color: black;
  font-size: 14px;
}

.form-control.form-control-lg {
  font-size: 11pt;
  color: black;
}

.user-enm-form p {
  font-size: 12px;
  font-weight: 400 !important;
  color: #000000b0;
}

.alert-floating {
  background: #6c2d88;
  position: fixed;
  width: 100%;
  /* height: 90px; */
  color: #fff;
  left: 0;
  top: 0;
  z-index: 1080;
  text-align: center;
}

.alert-floating p {
  font-size: 24px;
  font-weight: 500;
  margin: 0.8rem auto;
}

img.img-fluid.ec-img {
  height: 132px;
  width: 100%;
  object-fit: contain;
}

.user-flow .user-form form .user-form-buttons {
  position: absolute;
  right: 42px;
  bottom: 40px;
}

footer {
  margin-top: 4rem;
}

.ec-modal.ec-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(127 127 127 / 97%);
  z-index: 9999999;
  top: 0;
  left: 0;
}

.ec-modal.ec-overlay .ec-dialog .ec-body p {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
}

.ec-modal.ec-overlay .ec-dialog {
  background: #fff;
  width: 50%;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 18px 22px;
}

.close-modal i {
  display: block;
  cursor: pointer;
  color: #868686;
  font-size: 18px;
  padding-top: 0.6rem;
}

.ec-body button {
  font-size: 16px;
  padding: 5px 18px;
  background: rgb(108 45 136);
}

.close-modal {
  display: block;
  text-align: right;
  margin-bottom: 1.8rem;
}

.ec-body .user-form-buttons {
  margin-top: 8rem;
}

@media (max-width: 746px) {
  .ec-modal.ec-overlay .ec-dialog {
    width: 80%;
  }

  .ec-body .user-form-buttons {
    margin-top: 1rem;
    width: 100%;
    text-align: center !important;
  }
}

.ec-modal.ec-overlay .ec-dialog .ec-body p b {
  color: #666;
}

.alert-red {
  color: red;
}

.profoile-jobs {
  display: block;
  margin-bottom: 1rem;
}

.profoile-jobs ul.job-ctg {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.profoile-jobs ul.job-ctg li {
  float: left;
  margin-bottom: 0.4rem;
}

.profoile-jobs ul.job-ctg li a {
  border: 1px solid #d22d89;
  padding: 0.4rem 1.2rem;
  display: inline-block;
  color: #333;
  margin-right: 1rem;
  border-radius: 14px;
  cursor: pointer;
  position: relative;
  font-weight: 700;
}

ul.job-ctg li a span {
  display: block;
  font-size: 10px;
  color: #333;
  pointer-events: none;
  font-weight: 500;
}

ul.job-ctg li:hover a,
ul.job-ctg li.active a {
  color: #d22d89;
}

ul.job-ctg li.active a {
  padding: 0.4rem 2rem;
}

ul.job-lists {
  list-style: none;
  padding: 1rem;
  display: none;
}

.profoile-jobs ul.job-ctg li.active ul.job-lists {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  width: 42rem;
  flex-wrap: wrap;
}

ul.job-lists li {
  width: 50%;
}

.dashboard .profile .profoile-jobs ul.job-ctg li.active {
  float: none;
  width: 100%;
}

ul.job-lists li label {
  color: #333;
  margin-bottom: 0;
}

.pj-selected {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.pj-selected span {
  color: #333;
  font-weight: 500;
  margin-right: 1rem;
}

.pj-selected ul {
  list-style: none;
  padding: 0;
}

.pj-selected ul li {
  float: left;
  margin-right: 1rem;
  color: #d22d89;
}

.pj-selected ul li i {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #d22d89;
  border-radius: 50%;
  color: #fff;
  font-size: 10px !important;
  padding: 0 5px;
  line-height: 1.5;
  cursor: pointer;
}
</style>
<style>
footer {
  margin-top: 4rem !important;
}

.pj-loading {
  width: 100%;
  height: 201px;
  background: rgb(235 235 235);
  top: 0;
  left: 0;
  position: relative;
  border-radius: 8px;
}

.pj-loading i {
  position: absolute;
  top: 46%;
  left: 46%;
  font-size: 36px !important;
  color: #d22d89;
  transform: translate(-50%, -50%);
}

.input-group input {
  padding: 8px 8px 8px 44px;
  font-size: 14px;
  margin-bottom: 1rem;
  background-size: 16px;
}

.autocomplete-result {
  padding: 8px 8px 8px 44px;
  background-size: 16px;
}

.autocomplete-result-list {
  margin-top: -1rem !important;
}

.active a span:after {
  content: "";
  width: 0;
  height: 0;
  border-right: 1.6rem solid transparent;
  border-left: 1.6rem solid transparent;
  /* border-top: 1.5rem solid #CCCCCC; */
  position: absolute;
  bottom: -10px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-top: 0.4rem solid #d22d89;
}

/* Checkbox Styles */
.control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e0dddd;
  border: 0px solid #000000;
  border-radius: 0px;
  pointer-events: none;
}

.control:hover input~.control_indicator,
.control input:focus~.control_indicator {
  background: #cccccc;
}

.control input:checked~.control_indicator {
  background: #d22d89;
}

.control:hover input:not([disabled]):checked~.control_indicator,
.control input:checked:focus~.control_indicator {
  background: #0e6647;
}

.control input:disabled~.control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}

.control input:checked~.control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled~.control_indicator:after {
  border-color: #7b7b7b;
}

.control-checkbox .control_indicator::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.9rem;
  margin-top: -0.9rem;
  background: #d22d89;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes s-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.control-checkbox input+.control_indicator::before {
  animation: s-ripple 250ms ease-out;
}

.control-checkbox input:checked+.control_indicator::before {
  animation-name: s-ripple-dup;
}

.hard-skills.required input,
.core-skills.required input {
  border: 1px solid red;
}
</style>
